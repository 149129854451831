import React from 'react';
import styled from "styled-components";
import { IconDownCircle } from "@util/assets";
import { colors, colorsInverse } from '@util/constants';

const ButtonDown = styled.div<{
  inverse?: boolean
}>`
  cursor:pointer;
  display:flex;
  align-items:center;
  color: ${({ inverse }) => inverse ? colors.textBody : colorsInverse.textBody};
  svg{
    width: 26px;
    height: 26px;
    margin-right:15px;
    path{
      stroke: ${({ inverse }) => inverse ? colors.textBody : colorsInverse.textBody};
    }
    circle{
      stroke: ${({ inverse }) => inverse ? colors.textBody : colorsInverse.textBody};
    }
  }
`

interface Props {
  data: {
    text: string,
    inverse?: boolean
  }
}

function buttonDown({data}: Props) {
  if(data == null) return null;

  return (
    <ButtonDown>
      <IconDownCircle />
      <span>{data.text}</span>
    </ButtonDown>
  )
}

export default buttonDown