import React, {useRef} from "react";
import { SanityHero, Maybe } from "@graphql-types";
import { Image } from "@global";
import { BlockContent } from "@global";
import Video from "@shared/sub/video";
import { useCheckScreenSize } from "@util/hooks";
import ButtonDown from "@shared/sub/buttonDown";
import { RowImage, RowContent, Content, HeroSection, KontainerContent } from "./hero.styles";
import { MarginBottom } from "@util/types";

interface Props {
  data: Maybe<SanityHero> | undefined;
}

function hero({ data }: Props) {
  if (data == null) return null;
  const {
    backgroundImage,
    backgroundImageMobile,
    description,
    title,
    ctaScrollButtonText,
    vimeoId,
    marginBottom,
    height,
  } = data;

  const { isSmallDown } = useCheckScreenSize();
  const imageToLoad = !backgroundImageMobile
    ? backgroundImage
    : isSmallDown
    ? backgroundImageMobile
    : backgroundImage;

  const heroSectionRef = useRef<HTMLElement>(null);

  const scrollBelowHero = () => {
    if(heroSectionRef == null) return;
    const { current } = heroSectionRef;
    const scrollToNumber = (current?.offsetHeight ? current.offsetHeight : 0);
    window.scroll(0, scrollToNumber);
  }

  return (
    <HeroSection
    ref={heroSectionRef}
    mb={marginBottom as MarginBottom}
    heightStyle={height}
    imageAvailable={imageToLoad ? true : false}
    >
      {imageToLoad && <RowImage><Image isBackground={true} data={imageToLoad} /></RowImage>}

      {vimeoId && (
        <Video id={vimeoId} url={vimeoId} volume={false} pause={true} autoPlayOnLoad={true} />
      )}

      <RowContent>
        <div>
          <KontainerContent size="wide">
            <Content split={description ? true : false}>
              <BlockContent data={title} inverse={true} />
              {description && <BlockContent data={description} inverse={true} />}
            </Content>
          </KontainerContent>

          {ctaScrollButtonText && (
            <KontainerContent size="wide">
              <span onClick={scrollBelowHero}>
              <ButtonDown data={{ text: ctaScrollButtonText, inverse: true }} />
              </span>
            </KontainerContent>
          )}
        </div>
      </RowContent>
    </HeroSection>
  );
}

export default hero;
