import React from "react";

import { Kontainer } from "@util/standard";
import {
  BlockContent,
  LeftRightText,
  PeopleGrid,
  PreviewGrid,
  FullWidthImageWithText,
  VideoSection,
  GridText,
  StaticImage,
  GridOutlineNews,
  GridOutline,
  GoogleMapSection,
  FormSection} from "@global";
import {
  isSanityContent,
  isSanityGridProfile,
  isSanityGridProfilePeople,
  isSanityLeftRightText,
  isFullWidthImageWithText,
  isSanityVideo,
  isSanityGridText,
  isSanityStaticImage,
  isSanityGridOutlineNews,
  isSanityGridOutline,
  isSanityGoogleMap,
  isSanityForms
} from "@util/types";

interface Props {
  data: any;
}

export default function Sections({ data }: Props) {
  if (data == null) return null;

  const Section = () => {
    
    if (isSanityContent(data)) {
      return (
        <Kontainer size={data.containerWidth}>
          <BlockContent data={data.text} />
        </Kontainer>
      );
    }

    if (isSanityLeftRightText(data)) {
      return <LeftRightText data={data.leftRightTextItem} />
    }

    if (isFullWidthImageWithText(data)) {
      return <FullWidthImageWithText data={data} />
    
    }
    if (isSanityGridProfile(data)) {
      return <PreviewGrid data={data} />
    }

    if (isSanityGridProfilePeople(data)) {
      return <PeopleGrid />;
    }

    if (isSanityVideo(data)) {
      return <VideoSection data={data} />;
    }

    if (isSanityGridText(data)) {
      return <GridText data={data} />;
    }

    if (isSanityStaticImage(data)) {
      return <StaticImage data={data} />;
    }

    if (isSanityGridOutlineNews(data)) {
      return <GridOutlineNews data={data} />;
    }

    if (isSanityGridOutline(data)) {
      return <GridOutline data={data} />;
    }

    if (isSanityGoogleMap(data)) {
      return <GoogleMapSection data={data} />;
    }

    if (isSanityForms(data)) {
      return <FormSection data={data} />
    }

    return null;
  };

  if (Section() == null) {
    //TODO: change to null when going live
    return <h1 style={{ color: "red" }}>Error loading section...</h1>;
  }

  return (
    <Kontainer mb={data.marginBottom}>
      <Section />
    </Kontainer>
  );
}
