import React from "react";
import { graphql, PageProps } from "gatsby";

import { Query } from "@graphql-types";
import SEO from "@shared/seo";
import Hero from "@shared/hero/hero";
import Sections from "@shared/sections";

interface Props extends PageProps {
  data: Query;
}

export default function page({ data }: Props) {
  const { sanityPage } = data;
  if (sanityPage == null) return null;
  const { hero, blocks, seo } = sanityPage;

  return (
    <div>
      <SEO seoData={seo} />
      <Hero data={hero} />
      {blocks && blocks.map(block => <Sections key={block?._key} data={block} />)}
    </div>
  );
}

export const query = graphql`
  query pageQuery($slug: String) {
    sanityPage(slug: { current: { eq: $slug } }) {
      seo {
        ...sanitySeo
      }
      hero {
        ...sanityHero
      }
      blocks {
        ... on SanityVideo {
          ...sanityVideo
        }
        ... on SanityLeftRightText {
          ...sanityLeftRightText
        }
        ... on SanityGridText {
          ...sanityGridText
        }
        ... on SanityGridProfilePeople {
          _key
          _type
          marginBottom
        }
        ... on SanityGridProfile {
          ...sanityGridProfile
        }
        ... on SanityGridOutlineNews {
          ...sanityGridOutlineNews
        }
        ... on SanityGridOutline {
          ...sanityGridOutline
        }
        ... on SanityFullWidthImageWithText {
          ...sanityFullWidthImageWithText
        }
        ... on SanityForms {
          ...sanityForms
        }
        ... on SanityContent {
          ...sanityContent
        }
        ... on SanityStaticImage {
          ...sanityStaticImage
        }
        ... on SanityGoogleMap {
          ...sanityGoogleMap
        }
      }
    }
  }
`;
