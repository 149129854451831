import styled from "styled-components";
import { absoluteFill, linear, marginBottom, mediaQuery, PRIMARY_COLOR } from "@util/constants";
import { Kontainer } from "@util/standard";
import { MarginBottom } from "@util/types";

const defaultHeight = `
  height:81vh;
`;
export const HeroSection = styled.section<{
  mb?: MarginBottom;
  heightStyle?: string | null;
  imageAvailable: boolean;
}>`
  position: relative;
  &:after{
    content:'';
    ${absoluteFill};
    background:${linear.dark};
    z-index:1;
  }
  ${({ imageAvailable }) => !imageAvailable && `
    background-color: ${PRIMARY_COLOR};
  `}
  ${({ heightStyle }) =>
    (!heightStyle && defaultHeight) ||
    (heightStyle === "default" && defaultHeight) ||
    (heightStyle === "tall" &&
      `
      height:91vh;
      ${mediaQuery.mediumDown}{
        height:100vh;
      }
      &:after{
        background:${linear.darker};
      }
    `)}

  ${({ mb }) => mb && `${marginBottom[mb]};`}
`;

export const Content = styled.div<{
  split?: boolean;
}>`
  width: 100%;
  ${({ split }) =>
    split ?
    `
    max-width: 700px;
    display: grid;
    grid-gap:50px;
    grid-template-columns:1fr 1fr;
    ${mediaQuery.mediumDown}{
      grid-template-columns: 1fr;
      grid-gap:15px;
    }
  ` : `max-width: 750px;`  
  }
`;

export const RowContent = styled.div`
  ${absoluteFill}
  z-index:5;
  display: grid;
  align-items: end;
  margin-bottom: 69px;
`;
export const RowImage = styled.div`
  ${absoluteFill}
`;

export const KontainerContent = styled(Kontainer)`
  margin-bottom: 69px;
`;
